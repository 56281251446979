export default {
  init() {
    // JavaScript to be fired on all pages
    $('.slider-videos .slider-inner').slick({
      centerMode: false,
      autoplay: false,
      slidesToShow: 1,
      dots: false,
      infinite: true,
      fade:true,
      speed: 300,
      variableWidth: false,
      swipeToSlide: false,
      cssEase: 'ease-in',
      prevArrow: '.slider-videos .slider-control .slider-prev',
      nextArrow: '.slider-videos .slider-control .slider-next',
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            autoplay: true,
            variableWidth: false,
            adaptiveHeight: true,
          },
        },
      ],
    });

    $('.slider .slider-inner').slick({
      centerMode: false,
      autoplay: true,
      slidesToShow: 4,
      dots: false,
      infinite: true,
      fade: false,
      ssEase: 'linear',
      speed: 800,
      variableWidth: false,
      swipeToSlide: true,
      cssEase: 'ease-in',
      prevArrow: '.slider-home .slider-control .slider-prev',
      nextArrow: '.slider-home .slider-control .slider-next',
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            autoplay: true,
            variableWidth: false,
            adaptiveHeight: true,
          },
        },
      ],
    });

    $('.slider-projects .slider-inner').slick({
      centerMode: false,
      autoplay: true,
      slidesToShow: 1,
      dots: true,
      infinite: true,
      fade: true,
      ssEase: 'linear',
      speed: 800,
      variableWidth: false,
      swipeToSlide: true,
      cssEase: 'ease-in',
      prevArrow: '.slider-projects .slider-control .slider-prev',
      nextArrow: '.slider-projects .slider-control .slider-next',
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            autoplay: true,
            variableWidth: false,
            adaptiveHeight: true,
          },
        },
      ],
    });


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
